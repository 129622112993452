//* Footer styles

footer {

    .footerTop {
        padding: 50px 0;
        img {
            width: 100%;
            height: auto;
            max-width: 200px;
            display: block;
            margin: 0 auto 20px;
        }

        p {
            color: $white;
            text-align: left;
            font-size: 14px;
        }

        .footer-call-to-action {
            text-align: center;

            a.shadow {
                display: block;
                border-radius: 50%;
                max-width: 100px;
                width: 100%;
                margin: 0 auto 15px auto;
                -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
                -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
                box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
                img {
                    max-width: 100px;
                    height: auto;
                    width: 100%;
                    display: block;
                    margin: 0 auto;
                }
            }

            .btn {
                
                display: block;
                margin: 1em auto;

                @media all and (min-width:$screen-sm-min) {
                    display: inline-block;
                    margin: auto;
                }

                // display: inline-block;

                // @media all and (min-width: 768px) and (max-width:$screen-lg-min) {
                //     display: block;
                // }
                
            }

            p {
                display: inline-block;
                padding: 0 10px;
                margin-bottom:0;
                font-weight: $weight-semibold; 

                @media all and (min-width: 768px) and (max-width:$screen-lg-min) {
                    display: block;
                    padding: 10px;
                }
            }

            h4 {
                font-size: 1.2rem;
                color: $white;
                margin: 0 auto 20px auto;
                display: block;
                text-transform: uppercase;

            }
        }
    }

    p {
        text-align: center;
        @media all and (min-width: $screen-xs-max) { 
            text-align: left;
        }
        color:$lightGrey;
        font-size: 14px;
    }

    img.paymentLogo {
        max-width: 250px;
        height: auto;
        width: 100%;
        margin: 0 auto;
        position: relative;
        display: block;
        @media all and (min-width: $screen-xs-max) {
            margin-right: 0;
        }
    }
}