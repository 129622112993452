//* Base font and font weights.
$type-base          :      "Montserrat", sans-serif;
$weight-light       :       300;
$weight-regular     :       400;
$weight-medium      :       500;
$weight-semibold    :       600;
//* H1 to H6
@include headings(1, 6) {
    font-family: $type-base;
    font-weight: $weight-semibold;
    @if $use-font-smoothing == true {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

//* Generic
body, p, ul, li, a{
    font-family: $type-base;
    @if $use-font-smoothing == true {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    @include fluid-type(16px, 17px);
    font-weight: $weight-regular;
}

//* H1 to h6 individuals
h1,.h1 {
    @include fluid-type(28px, 50px);
}
h2,.h2 {
    @include fluid-type(26px, 30px);
}
h3,.h3 {
    @include fluid-type(24px, 32px);
}
h4,.h4 {
    @include fluid-type(22px, 28px);
}
h5,.h5{
    @include fluid-type(18px, 26px);
}
h6,.h6{
    @include fluid-type(16px, 22px);
}

//* links
a{
    color: $secondary-colour;
    transition: color 0.2s;

    &:hover{
        text-decoration: none;
        color: $secondary-colour;
        transition: color 0.2s;
    }
}

//* Custom
.font--weight_base
{
    font-family: $type-base;
}
.font--weight_bold
{
    font-family: $weight-semibold;
}

.font--weight_reg {
    font-weight: $weight-regular;
}

.font--uppercase
{
    text-transform: uppercase;
}

.header--title {
    position: relative;
    display: block;
    z-index: 10;

    &:before {
        content:'';
        position: absolute;
        // display: block;
        top:-5px;
        left:0;
        height:2px;
        width: 50px;
        background-color: $blue;
    }
}

.white {
    color: $white;
}

.font-blue {
    color: $blue;
}

.align-center {
    text-align: center;
}

.subTitle-text {
    font-weight:$weight-regular;
    font-size: 1rem;
}

.subtitle-text-med {
    font-size: 1.5rem;
}
