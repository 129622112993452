//* Loop for button colours
// Generate Button Classes 
@each $x in $buttons {
  .btn--colour_#{nth($x,1)} {
    background-color: #{nth($x,2)};
    color: #{nth($x,3)};
    transition: background 0.2s;
    &:hover
    {
      background-color: #{nth($x,4)};
      color: #{nth($x,5)};
      transition: background 0.2s;
      cursor: pointer;
    }
  }
}