// 00 - Base Options
@import "00-core/_colours.scss";
@import "00-core/_base-options.scss";

// 01 - Mixins
@import "01-mixins/_absolute.scss";
@import "01-mixins/_background-colours.scss";
@import "01-mixins/_background-opacity.scss";
@import "01-mixins/_buttons.scss";
@import "01-mixins/_centering.scss";
@import "01-mixins/_checkbox-input.scss";
@import "01-mixins/_font-colours.scss";
@import "01-mixins/_font-face.scss";
@import "01-mixins/_font-size.scss";
@import "01-mixins/_titles.scss";

// 00 - Core files
@import "00-core/_breakpoints.scss";
@import "00-core/_fonts.scss";
@import "00-core/_typography.scss";

// 02 - Layout files
@import "02-layout/_utility.scss";
@import "02-layout/_shame.scss";
@import "02-layout/_nav.scss";
@import "02-layout/_header.scss";
@import "02-layout/_sliders.scss";
@import "02-layout/_footer.scss";

// 03 - Components
@import "03-components/_btns.scss";
@import "03-components/_sliders.scss";
@import "03-components/_icons.scss";
@import "03-components/_images.scss";
@import "03-components/_cta.scss";
@import "03-components/_lists.scss";
@import "03-components/_videos.scss";
@import "03-components/_contact-form.scss";

// 04 - Client
@import "04-client/_login.scss";

// 05 - UI
@import "05-ui/_transitions.scss";

// 06 - Tools
@import "06-tools/_html-validator.scss";
@import "06-tools/_breakpoint-identifier.scss";

