//* All Colours
$white: #ffffff;
$black: #000000;
$blue:#0059e8;
$darkBlue: #292c34;
$green: #109647;
$lightGrey: #828282;
$serviceGrey: #e9e9e9;

//* Main colours
$primary-colour: $green;
$secondary-colour: $blue;



//* Buttons
// Name, BG Colour, text-colour, hover bg colour, hover text colour
$buttons: (
  ("primary", $primary-colour, $white, lighten($primary-colour, 10%), $white),
  ("secondary", $secondary-colour, $primary-colour, darken($secondary-colour, 20%), $primary-colour),
);

//* Fonts
$fonts: (
    primary: $primary-colour,
    secondary: $secondary-colour,
    plain: $white,
);

//* Background Colour
// NOTE: bg--colour_option e.g .bg--colour_primary
$bg: (
    primary: $primary-colour,
    secondary: $secondary-colour,
    plain: $white,
    blue: $blue,
);
