//* Header Styles
header {
    background-color: $darkBlue;
    padding: 25px 0;
    position: relative;
    z-index: 1000;

    .logo {
        img {
            width: 100%;
            max-width: 300px;
            height: auto;
        }
    }
}

.headerWrap {
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
}

a.mobilePhone {
    display: block;

    img {
        width: 100%;
        height: auto;
    }
}

#inner-header {
    position: relative;
    height: 35vh;
    min-height: 150px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    p#breadcrumbs {
        color: $white;
        text-transform: uppercase;
        position: relative;
        display: block;
        z-index: 10;
        font-weight: 600;

        a {
        color: $white;
        }
    }

    h1 {
        font-size: calc(26px + 4 * ( (100vw - 576px) / 623));
       @media all and (min-width: 1199px) {
        font-size: 30px;
       }
    }

    &:after {
        content:'';
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        background: rgb(0,0,0);
        background: linear-gradient(138deg, rgba(0,0,0,0.6194852941176471) 0%, rgba(73,73,75,0.5830707282913166) 39%, rgba(255,255,255,0) 100%);
    }
}
