.heroSlider {
    .slide {
        // @include display-center;
        height: 65vh;
        min-height: 550px;
        position: relative;

        .slideImage {
            position: absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
        }

        &:before {
            content:'';
            position: absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            z-index: 10;
            background: rgb(0,0,0);
            background: linear-gradient(180deg, rgba(0,0,0,0.6194852941176471) 0%, rgba(73,73,75,0.5830707282913166) 39%, rgba(255,255,255,0) 100%);
        }

        .slideInner {
            // @include display-table-cell;

            .slideText {
                position: relative;
                z-index: 10;

                h2, h4 {
                    color: $white;
                    position: relative;
                    display: block;
                    text-transform: uppercase;
                }

                h2 {
                    // font-size: 3rem;
                }

                h4 {
                    font-size: 1.2rem;
                    margin-bottom: 25px;

                    &:after {
                        content:'';
                        position: absolute;
                        bottom: -15px;
                        left:0;
                        height: 2px;
                        width: 20%;
                        background-color: $blue;
                    }
                }

                p {
                    color: $white;
                }

                span {
                    color: $white;
                    padding: 0 15px;
                    font-weight: $weight-semibold;
                }
            }
        }
    }
}

.sliderWrapper {
    position: relative;

    .carousel-control-prev, .carousel-control-next {
        position: absolute;
        top:0;
        bottom:0;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            display: inline-block;
            width: 50px;
            height: 60px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url(../images/next.svg);
        }
    }
    
    .carousel-control-prev {
        span {
            background-image: url(../images/prev.svg);  
        }
    }

}

.logoSlider {
    .logoSlider {
        img {
            width: 100%;
            height: auto;
            display: block;
            margin: 0 auto;
        }
    }
}

.quoteSlider {
    text-align: center;
    color: $white;
    .quote {
        max-width: 80%;
        margin: 0 auto;
        .iconImage {
            position: relative;
            img {
                max-width: 40px;
                height: auto;
                display: block;
                margin: 0 auto 10px;
                position: relative;
    
            }
            &:after {
                content:'';
                position: absolute;
                bottom:0;
                left:0;
                right:0;
                margin: 0 auto;
                height: 2px;
                width: 10px;
                background-color: $blue;
            }
        }
        p {
            color: $white;

            &.testTitle {
                font-weight: 600;
                margin-bottom:0;
                margin-top: 10px;
            }

            span {
                color: $blue;
                font-weight: inherit;
            }
        }
    }
}

.slick-dots {
    list-style-type: none;
    text-align: center;
    padding: 0;
    margin: 10px auto 0;

    li {
        display: inline-block;
        position: relative;

        &.slick-active {
            button {
                &:before {
                    background-color: $blue;
                    opacity:1;
                }
            }
        }

        button {
            position: relative;
            font-size: 0;
            line-height: 0;
            display: block;
            width: 20px;
            height: 20px;
            padding: 5px;
            cursor: pointer;
            color: transparent;
            border: 0;
            outline: none;
            background: transparent;

            &:before {
                font-family: 'slick';
                font-size: 6px;
                line-height: 20px;
                position: absolute;
                top: 0;
                left: 0;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                content: '';
                text-align: center;
                opacity: 0.6;
                background-color:$white;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
            
        }
    }
}