.medium-padding {
    padding: 25px 0;
}
.drkBackground {
    background-color: $darkBlue;
}

.lightBackground {
    background-color: $blue;
}

.title--blue-underline {
    position: relative;

    &:after {
        content:'';
        height: 2px;
        background-color: $blue;
        width: 60px;
        position: absolute;
        bottom: -10px;
        left:0;
        right:0;
        margin: 0 auto;
    }

    &.underline-left {
        &:after {
            margin-left:0;
        }
    }
}

.padding-top-large {
    @media all and (min-width:$screen-sm-max) {
        padding-top: 150px!important;
    }
}

.imageContainer {
    position: relative;
    &.largeImage {
        @media all and (min-width:$screen-sm-max) {
           position: relative;
           top: 125px;
        }
        img {
           width: 100%;
           height: auto;
           @media all and (min-width:$screen-sm-max) {
            width: 140%;
         }
        }
        &.down {

        }
    }
}

.fullImage {
    height: 450px;
    position: relative;

    @media all and (min-width:$screen-xxs-max ) {
        height: auto;
        &:before {
            content:'';
            display: block;
            padding-top: 65%;
        }
    
    }
}

.fullHeight {
    position: absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

#map--wrapper {
    position: relative;
    width: 100%;
    display: block;
    height: 45vh;
    iframe {
        position: absolute;
        top:0;
        left:0;
       width: 100%;
       height: 100%;
    }
}

.acf-map {
	width: 100%;
	height: 550px;
}

.acf-map img {
   max-width: inherit !important;
}

.content-text {
    position: relative;

    .inner {
        position: relative;
        max-width: 75%;
        margin: 0 auto;

        ul {
            padding-left: 0;
            margin-left: 0;
            color: $white;
            list-style-type: none;

            li {
                margin-bottom: 25px;
                position: relative;
                padding-left: 25px;
                @media all and (min-width:$screen-md-min) {
                    padding-left:0;
                }

                &:before {
                    content: '';
                    position: absolute;
                    left: -15px;
                    top: 0;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background-color: $blue;
                    background-image: url(../images/tick.svg);
                    background-size: 40%;
                    background-position: center center;
                    background-repeat: no-repeat;

                    @media all and (min-width:$screen-md-min) {
                        left: -25%;
                        top: 0;
                        width: 40px;
                        height: 40px;
                    }

                    @media all and (min-width:$screen-lg-min) {
                        left: -23%;
                        top: 0;
                        width: 60px;
                        height: 60px;
                    }
                }

                &:last-child {
                    margin-bottom:0;
                }
                h4 {
                    font-size: 1.3rem;
                    text-transform: uppercase;
                }

                p {
                    font-size: 1rem;
                }
            }
        }
    }
}

#contact {
    position: relative;
    @media all and (min-width:$screen-xxs-max ) {
        // height: 60vh;
    }

    .front-contact {
        position: relative;
        padding: 25px 0;
        background-image: url(../images/contact-form-background.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        
        
        .front-contact-inner {
            position: relative;
            max-width: 75%;
            margin: 0 auto;
        }
    }
}

.service-intro {
    @media all and (min-width: $screen-sm-max) {
        -webkit-column-count: 2; /* Chrome, Safari, Opera */
        -moz-column-count: 2; /* Firefox */
        column-count: 2;
    }
}