#promoBar {
    position: relative;


        .calloutBar {
            padding: 35px 25px;
            background-color: $blue;
            text-align: center;
            position: relative;
            border-radius: 10px;
            top:-80px;
            -webkit-box-shadow: 0px 8px 12px -5px rgba(154,153,153,1);
            -moz-box-shadow: 0px 8px 12px -5px rgba(154,153,153,1);
            box-shadow: 0px 8px 12px -5px rgba(154,153,153,1);
                &.small-top {
                    top: -50px;
                }

                &.no-padding {
                    padding: 0;
                }

                &.white-background {
                    background-color: $white;
                }

                a {
                    &.active {
                        color: $blue;
                    }
                }

            .calloutInner {
                max-width: 80%;
                margin: 0 auto;
                h1, h2 {
                    color: $white;
                    font-size: 1.5rem;
                    margin-bottom: 10px;
                    text-transform: uppercase;
                    @media all and (min-width: 960px) {
                        font-size: 2rem;
                    }
                }
    
                p {
                    color: $white;
                }

                a.btn {
                    display: block;
                    @media all and (min-width:$screen-sm-min) {
                        display: inline-block;
                        margin: auto;
                    }
                    @include fluid-type(16px, 17px);
                }
            }
        }

}

.service-container {
    background-color: $white;

    &:nth-child(odd) {
        background-color: $serviceGrey;
    }

    .contact-information {
    -webkit-box-shadow: 0px 8px 12px -5px rgba(154,153,153,1);
    -moz-box-shadow: 0px 8px 12px -5px rgba(154,153,153,1);
    box-shadow: 0px 8px 12px -5px rgba(154,153,153,1);
    }
}

#contact-callout {
    position: relative;
}

.inner-contact {
    position: relative;

    .front-contact-inner {
        position: relative;
        max-width: 5%;
        margin: 0 auto;
    }
}

.contact-section {
    position: relative;

    .form-wrap {
        max-width: 80%;
        margin: 0 auto;
    }
}

.vanSection {
    height: 350px;
    overflow-x: hidden;

    @media all and (min-width: $screen-sm-min) {
        height: 60vh;
        min-height: 350px;

    }
    position: relative;
    background-image: url(../images/blue-background-slanted.png);
    background-size: 65%;
    background-position: top right;
    background-repeat: no-repeat;

    img {
        position: absolute;
        bottom:0;
        left:0;
        max-width: 780px;
        width: 100%;
        height: auto;
        margin: 0 auto;
    }
}

.serviceLinks {

    &:nth-of-type(odd) {
        .row {
            -webkit-box-orient: horizontal!important;
            -webkit-box-direction: reverse!important;
            -ms-flex-direction: row-reverse!important;
            flex-direction: row-reverse!important
        }
    }

    .imgContainer {
        position: relative;
        border-radius: 5px;
        display: block;
        img {
            border-radius: 10px;
        }

        .infoBox {
            padding: 15px 0;
            background-color: $blue;
            border-radius: 15px;
            margin-top:10px;
        
            @media all and (min-widtçh: 960px) {
                position: absolute;
                bottom: 25px;
                width: 125px;
                height: 125px;
                z-index:99;
                border-radius: 50%;
                transition: background-color 0.3s ease-in-out;
                -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
                -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
                box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);

                &.right {
                    right: 25px;
                    @media all and (min-width: 960px) {
                        right: -25px;
                    }
                }
    
                &.left {
                    left:25px;
                    @media all and (min-width: 960px) {
                        left: -25px;
                    }
                }
    
            }
           
            @media all and (min-width: 960px) {
                bottom: -25px;
            }

            

            a {
                color: $white;
                text-transform: uppercase;
                font-weight: 600;

            }
        }
    }

    h2 {
        margin-bottom: 1.5rem;
        @media all and (min-width: 1199px) {
            font-size: 30px;
        }
    }
    ul {
        list-style-type: none;
        li {
            position: relative;
            padding: 0 0 5px;
            margin-bottom: 10px;
            text-transform: capitalize;

            a {
                color: $black;
                transition: all 0.3s ease-in-out;
                cursor: pointer;

                &:hover{
                    color: $blue;
                }
            }

            &:before {
                content: '';
                position: absolute;
                left: -40px;
                top: 0;
                font-size: 1.5rem;
                width: 30px;
                height: 30px;
                background-image: url(../images/tick.svg);
                background-position: center center;
                background-size: 50%;
                background-repeat: no-repeat;
                background-color: $blue;
                border-radius: 50%;
            }
        }

    }
}
.serviceIntro {
    h2 {
        margin-bottom: 1.5rem;
        font-size: 2.3rem;
    }
}

.related {
    .thumbnail {
        img {
            border-radius: 10px;
            overflow: hidden;
        }
    }
    .content {
        h3 {
            font-size: 1.2rem;
        }
    }
}

#intro {
    ul {
        list-style-type: none;
        li {
            position: relative;
            padding: 0 0 5px;
            margin-bottom: 10px;
            text-transform: capitalize;

            a {
                color: $black;
                transition: all 0.3s ease-in-out;
                cursor: pointer;

                &:hover{
                    color: $blue;
                }
            }

            &:before {
                content: '';
                position: absolute;
                left: -40px;
                top: 0;
                font-size: 1.5rem;
                width: 30px;
                height: 30px;
                background-image: url(../images/tick.svg);
                background-position: center center;
                background-size: 50%;
                background-repeat: no-repeat;
                background-color: $blue;
                border-radius: 50%;
            }
        }

    }
}

#brandLogos {
    overflow: hidden;
}