.youtube--panel {
	background-color: #000;
	margin-bottom: 30px;
	position: relative;
	padding-top: 56.25%;
	overflow: hidden;
    cursor: pointer;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background: linear-gradient(rgba(0, 70, 157, 0.7),rgba(0, 70, 157, 0.7));

}
.youtube--panel .youtube--panel_play {
	width: 145px;
	height: 145px;
	z-index: 1;
    border-radius: 6px;
    
    img{
        width:145px;
    }
}

.youtube--panel iframe,
.youtube--panel .youtube--panel_play,{
    position: absolute;
    cursor: pointer;
}
.youtube--panel .youtube--panel_play{
	top: 50%;
	left: 50%;
	transform: translate3d( -50%, -50%, 0 );
}
.youtube--panel iframe {
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
}