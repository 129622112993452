//* Center vertical
@mixin center-vertically {
  position: absolute;
  top: 50%;
  left: 50%;
  @include prefix(transform, translate(-50%, -50%), 'webkit' 'ms');
}
//* Center Horizontal
@mixin center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@mixin display-center {
  display: table!important;
  width: 100%;
  height: 100%;
}

@mixin display-table-cell {
  display: table-cell;
  vertical-align: middle;
}