//* Button styles

.btn
{
    text-transform:uppercase;
    // display: flex;
    align-items:center;
    border-radius: 25px;
    padding: 10px 30px 10px 30px;
    font-weight: $weight-semibold;
    border: none;
    cursor: pointer;
    font-family: $weight-semibold;
    transition: all 0.3s ease-in-out;
    @include fluid-type(14px, 16px);
    cursor: pointer;
    @media(max-width: $screen-sm-max){
        margin:0 auto;
        text-align:center;
    }
}

.btn--align-center
{
    margin:0 auto;
    text-align:center;
}

.btn-blue {
    background-color: $blue;
    color: $white;
    border: 2px solid $blue;

    &:hover {
        background-color: $white;
    }

}

.margin-top {
    margin: 15px auto;
}

.mb-25 {
    margin-bottom: 25px;
}


.btn-white.blue-hover {
    -webkit-box-shadow: 0px 0px 10px 5px rgba(0,89,232,0);
        -moz-box-shadow: 0px 0px 10px 5px rgba(0,89,232,0);
        box-shadow: 0px 0px 10px 5px rgba(0,89,232,0);
    &:hover {
        background-color: $white;
        color: $blue;
        -webkit-box-shadow: 0px 0px 10px 5px rgba(0,89,232,0.48);
        -moz-box-shadow: 0px 0px 10px 5px rgba(0,89,232,0.48);
        box-shadow: 0px 0px 10px 5px rgba(0,89,232,0.48);
    }
}

.btn-white {
    background-color: $white;
    color: $blue;
    border: 1px solid $white;

    &:hover {
        background-color: $blue;
        color: $white;
    }
}

.btn--icon {
    padding-left: 45px;
}

.phone {
    background-image:url(../images/phone.svg);
    background-size: 30px;
    background-position: 8%;
    background-repeat: no-repeat;

    &:hover {
        background-image:url(../images/phone-white.svg);  
    }
}

.email {
    background-image:url(../images/email.svg);
    background-size: 23px;
    background-position: 5%;
    background-repeat: no-repeat;

    &:hover {
        background-image:url(../images/email-white.svg);  
    }
}

#menuBtn {
    
    width: 30px;
    right: 25px;

    span {
        position: relative;
        height: 2px;
        background-color: #fff;
        width: 100%;
        display: block;
        margin-bottom: 5px;
        transition: all 0.3s ease-in-out;

        &:last-child {
            margin-bottom:0;
        }
    }
}