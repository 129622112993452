/////
//
// Breakpoint Identifier
//
/////

@if $use-breakpoint-check == true {

    .breakpoint-identifier {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 5000;
        background: $primary-colour;
        color: white;
        border-radius: 5px 0 0 5px;
        box-shadow: 0 2px 5px 0 rgba(0,0,0,0.2);

        &:after {
            content:attr(data-width) ' x ' attr(data-height);
        }

    }

} @else {

    .breakpoint-identifier {
        display: none;
    }

}