//* Header navigation

ul#nav--footer {
    position: relative;
    padding: 15px 0;

    li {
        display: inline-block;
        position: relative;
        width: 50%;
        text-align: center;
        @media all and (min-width: $screen-lg-min ) {
            display: block;
            width: 100%;
            text-align: left;
        }

        a {
            color: $white;
            display: block;
            padding: 5px 0;
            position: relative;
            text-indent: 35px;
            font-size: 0.7rem;
            text-transform: uppercase;
            font-weight: $weight-semibold;
            transition: color 0.3s ease-in-out;
            
            &:hover {
                color: $darkBlue;
            }

            @media all and (min-width: $screen-lg-min ) {
                &:before {
                    content:'';
                    position: absolute;
                    left:0;
                    top:45%;
                    width: 25px;
                    height: 2px;
                    background-color: $white;
                }
            }
        }
    }
}

ul#nav--primary {
    display: flex;
    justify-content: flex-end;
    position: relative;
    align-items: center;
    @media all and (max-width: $screen-md-min ) {
        display: none;
    }
    
   

    li {
        display: inline-block;
        text-align: center;
        position: relative;

        &:hover {
            a {
                color: $blue;
            }
        }

        &.current-menu-item {
            &:before {
                content:'';
                position: absolute;
                top:-240%;
                left:0;
                right:0;
                height: 5px;
                width: 100%;
                background-color: $blue;
            }
        }


        &:last-child {

            padding-right:0;

            a {
                padding-right:0;
            }
        }

        &.nav-btn {
            @media all and (min-width:$screen-md-min) {
                border: 1px solid $white;
                border-radius: 30px;
            }
            a {
                padding: 10px 20px;
            }
        }

            a {
                color: $white;
                display: block;
                font-size: 15px;
                font-weight: $weight-semibold;
                text-transform: uppercase;
                padding: 0 10px;
                transition: color 0.3s ease-in-out;
            }

        &.mobile-number {
            @media all and (min-width:$screen-md-min) {
            background-image:url(../images/phone-white.svg);
            margin-left: 10px;
            border-left: 1px solid rgba(255, 255, 255, 0.2);
            }
            background-size: 15%;
            background-position: 10px center;
            background-repeat: no-repeat;
            padding: 0 0 0 35px;
        }
    }
}

#mobile-nav {
    overflow-x: hidden;
    position: fixed;
    opacity: 0;
    top: -100%;
    width: 100%;
    height: 100vh;
    width: 100vw;
    background:$blue;
    z-index: 900;
    transition: all 0.3s ease-in-out;

    ul#nav--primary {
        display: block;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        li {
            display: block;
            padding: 10px 0;

            &.current-menu-item {
                &:before {
                    display: none;
                }
            }
    

        }
    }
}

.menu-active {

    #menuBtn {
        span {
            &:first-child {
                transform: rotate(135deg);
                top: 8px;
            }

            &:nth-child(2) {
                opacity:0;
            }

            &:nth-child(3) {
                transform: rotate(45deg);
                top: -6px;
            }

        }
    }

    #mobile-nav {
        top:0;
        opacity: 1;
        
    }
}


.commercialTabs {
    list-style-type: none;
    text-align: center;
        li {
            display: inline-block;
            margin: 0;
            padding:0;
            a {
                padding: 10px 25px;
                @media all and (min-width: 967px) {
                    padding: 10px 25px;
                }
                background-color: $white;
                color: $darkBlue;
                display: inline-block;
                text-transform: uppercase;
                font-weight: $weight-semibold;
                transition: all 0.3s ease-in-out;

                &:hover, &.active {
                    color: $blue;
                }

                // &:hover {
                //     background-color: $blue;
                //     color: $white;
                // }
                
            }
        }
}

html {
    scroll-behavior: smooth;
  }

.circle-number {
    display: block;
    max-width: 100px;
    height: 100px;
    margin: 0 auto 25px;
    border-radius: 50%;
    -webkit-box-shadow: 0px 0px 18px 6px rgba(0,89,232,0.33);
    -moz-box-shadow: 0px 0px 18px 6px rgba(0,89,232,0.33);
    box-shadow: 0px 0px 18px 6px rgba(0,89,232,0.33);

    h4 {
        margin-bottom:0;
    }
}

.flexNumber {
    h3 {
        font-size: 1.4rem;
    }
}

.mobilePhoneIcon {
    max-width: 50px;
    width: 100%;
    height: auto;
    margin: 0 auto;
    display: block;
}