.iconBlockContainer {
    .block-single {
        .iconBlock {
            text-align: center;
            max-width: 80%;
            display: block;
            margin: 0 auto;
            position: relative;
            @media all and (min-width: 967px) {
                &:after {
                    content:'';
                    position: absolute;
                    right: -160px;
                    width: 250px;
                    top: 25%;
                    height: 2px;
                    background-color: rgba($white, 0.2);
                }
            }
    
    
            img {
                max-width: 110px;
                height: auto;
                margin: 0 auto 15px auto;
                position: relative;
                z-index: 10;
            }
    
            h3 {
                font-size: 1.4rem;
                color: $white;
            }
            p {
                color: $white;
                font-size: 0.9rem;
            }
    
        }

        &:last-child {
            .iconBlock {
                &:after {
                    display: none;
                }
            }
        }
    }

}

ul.social {
    list-style-type: none;
    display: block;
    text-align: center;

    @media all and (min-width: $screen-xs-max) { 
        text-align: left;
    }
    padding-left:0;
    margin-bottom:0;

    li {
        display: inline-block;
        a {
            color: $darkBlue;
            display: block;
            font-size: 1.4rem;
            padding: 10px 5px;
            transition: color 0.3s ease-in-out;

            &:hover {
                color: $blue;
            }
        }
    }
} 