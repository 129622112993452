.form-control {
    border:0;
    border-radius: 0;
    background-color: transparent;

    input, textarea {
      width: 100%;
      border: none;
      border-radius: 0;
      padding: 15px;
      @include fluid-type(16px, 17px);
      border-bottom: 2px solid #ced4da;

      &:focus {
        outline: 0;
      }
    }
}

.front-contact {
  .form-control {
    color: $white;
  }
}

textarea.form-control {
    margin-bottom: 15px;
}

.front-contact .form-control {

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $white;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: $white;
  }
  & :-ms-input-placeholder { /* IE 10+ */
    color: $white;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: $white;
  }

  input, textarea {
    width: 100%;
    border-radius: 0;
    background-color: transparent;
    border:0;
    box-shadow: 0;
    color: $white;
    padding: 15px;
    border-bottom: 2px solid #ced4da;
    @include fluid-type(16px, 17px);

    &:focus {
      outline:0;
    }

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $white;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: $white;
    }
    & :-ms-input-placeholder { /* IE 10+ */
      color: $white;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: $white;
    }
  }

}

  .contact-address {
      text-align: center;
        img {
            display: block;
            width: 35px;
            margin: 0 auto 15px;
            height: auto;
            position: relative;
        }

      p {
        margin-bottom:0;
      }
  }

  .form-control:focus {
    background-color: transparent;
    outline: none;
    box-shadow: none;
  }

  